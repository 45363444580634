import React, {useState} from "react"
import {Container, Row,Col } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import  './ContactBlock.scss';
import ReactMarkdown from "react-markdown"
import PlayVideo from "@Components/Play/PlayVideo";
import GetURL from '@Components/common/site/get-url';
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';
import loadable from "@loadable/component";
const VideoAsk = loadable(() => import("./VideoAsk"), {
  fallback: <div>Loading...</div>
});
const ContactBlock = ( props ) => {

  const [isPlay,setPlay] = useState(false);
  const closePlay = (id) => {
    setPlay(false)
  }

  const playVideo = (video_id) => {  
    // setVideoindex(video_index)   
    setShowVideo(true)
}
const [showVideo, setShowVideo] = useState(false);
const trackerVideo = (event) => {    
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'Video Play Btn',
    'formType': event,
    'formId': 'Video Play',
    'formName': 'Video',
    'formLabel': 'Video'
  });
}
  if (props.Video) {
    var videoid = getVideoId(props.Video);
   }
  return(
<div className="contact-block">
<Container>
   <Row className="align-items-center">
      <Col lg="4" className="order-lg-2">
      {/* <ScrollAnimation className="relative" animateIn='fadeIn' animateOnce={true}> */}
      <div className="relative" >
      {showVideo && <div onClick={(e) => {setShowVideo(false)}} className="close-iframe-btn"></div> }
      {props.Video_Ask ?
      <div className="videoask-frame">
        <VideoAsk src={props.Video} width="100%" height="200px" />
          {/* <iframe src={props.Video} title="Bentley Hurst" allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;" width="100%" height="330px" border="0"></iframe> */}
      </div>      
:
      <div className="contact-block-img img-zoom">
      

      {showVideo &&
                        <YouTube
                        video={videoid.id}
                        autoplay
                        onEnd={e => {setShowVideo(false)}}
                        modestBranding={1}
                        onPlaying={trackerVideo(props.Title)}
                        showRelatedVideos={false}
                        showInfo={false}
                        annotations={false}
                        

                        />
                        }

       <picture>
       <source media="(min-width:992px)" srcset={props.Image?.url} />
                <source media="(min-width:768px)" srcset={props.Image?.url} />
                <img className="bg-img" src={props.Image?.url} alt={props.Image?.alternativeText} />
        </picture>
        {props.Video ?
        <strong onClick = { (e) => {playVideo(videoid.id)}} className={"play-btn"} ><i className="icon-play"></i></strong>
        : ''}
       </div>
        }
       {/* </ScrollAnimation> */}
       </div>
      </Col>
     <Col lg="8" className="order-lg-1">
       <div className="contact-block-content">
       {/* <ScrollAnimation animateIn='fadeIn' animateOnce={true}> */}
       <h2>{props.Title}</h2>
       <div className="rich-text-editer-block">
         <ReactMarkdown source={props.Content} allowDangerousHtml />
         </div>
         
            <div className="contact-link">
            {props.CTA_1_Label != null && props.CTA_1_URL != null &&
            <GetURL label={props.CTA_1_Label} alias={props.CTA_1_URL.Alias} class="link-text" isArrow={true} />
            }
            {props.CTA_2_Label != null && props.CTA_2_URL != null &&
                        <GetURL label={props.CTA_2_Label} alias={props.CTA_2_URL.Alias} class="link-text" isArrow={true} />
            }
             </div>
          {/* </ScrollAnimation> */}
       </div>
     
     </Col>
     </Row>
     </Container>
     <PlayVideo
              isOpen = { isPlay } 
              isCloseFunction = { closePlay }
              videoId={props.Video}
              isAutoPlay={1}
            />
</div>
)
  }



export default ContactBlock